<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      :heading="accountName"
    />
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </v-banner>
    </template>
    <template v-if="lastUpdatedAt">
      <v-row justify="end">
        <span>
          <span class="font-weight-light font-italic">
            Last Updated At {{ formatIsoDate(lastUpdatedAt, true) }}
          </span>
          <v-btn
            text
            small
            @click="refreshCache"
          >
            Refresh
          </v-btn>
        </span>
      </v-row>
    </template>
    <v-row dense>
      <v-col cols="12">
        <base-material-card
          icon="mdi-car"
          :title="getAgeTitle()"
        >
          <v-row>
            <template
              v-for="(age, index) in ageStats"
            >
              <v-col
                :key="age.timeFrame"
                cols="12"
                sm="6"
                lg="3"
              >
                <router-link
                  :key="age.timeFrame"
                  class="age_boxes"
                  :to="{ name: 'Vehicles', query: { filter: age.filter }}"
                >
                  <v-card
                    class="mt-4 mx-auto"
                  >
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="headline">
                          {{ age.timeFrame }}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ age.current }} Vehicles</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <div class="text-center">
                      <v-progress-circular
                        :rotate="360"
                        :size="100"
                        :width="15"
                        :value="age.percentOfStock"
                        :color="getAgeBoxColor(index)"
                      >
                        <span class="font-weight-bold">
                          {{ age.percentOfStock }} %
                        </span>
                      </v-progress-circular>
                    </div>
                    <v-card-actions>
                      <template v-if="age.priorPercentOfStock > 0">
                        <p class="text--secondary">
                          A month ago, {{ age.prior }} vehicles ( {{ age.priorPercentOfStock }}%) were aged {{ age.timeFrame }}.
                        </p>
                      </template>
                    </v-card-actions>
                  </v-card>
                </router-link>
              </v-col>
            </template>
          </v-row>
          <template v-if="graphColorEmpty">
            <v-alert
              border="top"
              dense
              color="orange"
              elevation="6"
              :text="true"
            >
              You haven't specified any alert settings for the graph below. Click here to do that.
            </v-alert>
          </template>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              lg="3"
            >
              <router-link
                class="age_boxes"
                :to="{ name: 'Vehicles', query: { 'filter': 'not_on_website' }}"
              >
                <base-material-chart-card
                  :data="vehiclesNotOnWebsite.data"
                  :options="vehiclesNotOnWebsite.options"
                  :color="getGraphColor(graphColors.not_on_website_color)"
                  type="Bar"
                  class="px-4 py-3"
                >
                  <h4 class="display-1 font-weight-light mt-2">
                    {{ vehiclesNotOnWebsite.data.series[0][vehiclesNotOnWebsite.data.series[0].length - 1] }} Vehicles Missing From Website
                  </h4>
                </base-material-chart-card>
              </router-link>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              lg="3"
            >
              <router-link
                class="age_boxes"
                :to="{ name: 'Vehicles', query: { 'filter': 'needs_price_updates' }}"
              >
                <base-material-chart-card
                  :data="vehiclesWithNoPrice.data"
                  :options="vehiclesWithNoPrice.options"
                  :color="getGraphColor(graphColors.needs_pricing_color)"
                  type="Bar"
                  class="px-4 py-3"
                >
                  <h4 class="display-1 font-weight-light mt-2">
                    {{ vehiclesWithNoPrice.data.series[0][vehiclesWithNoPrice.data.series[0].length - 1] }} Vehicles That Need Price Updates
                  </h4>
                </base-material-chart-card>
              </router-link>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              lg="3"
            >
              <router-link
                class="age_boxes"
                :to="{ name: 'Vehicles', query: { 'filter': 'missing_photos' }}"
              >
                <base-material-chart-card
                  :data="vehiclesWithIncompletePhotos.data"
                  :options="vehiclesWithIncompletePhotos.options"
                  :color="getGraphColor(graphColors.incomplete_photos_color)"
                  type="Bar"
                  class="px-4 py-3"
                >
                  <h4 class="display-1 font-weight-light mt-2">
                    {{ vehiclesWithIncompletePhotos.data.series[0][vehiclesWithIncompletePhotos.data.series[0].length - 1] }} Vehicles With Incomplete Photos
                  </h4>
                </base-material-chart-card>
              </router-link>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              lg="3"
            >
              <router-link
                class="age_boxes"
                :to="{ name: 'Vehicles', query: { 'filter': 'no_activity' }}"
              >
                <base-material-chart-card
                  :data="vehiclesWithNoActivity.data"
                  :options="vehiclesWithNoActivity.options"
                  :color="getGraphColor(graphColors.no_activity_color)"
                  type="Bar"
                  class="px-4 py-3"
                >
                  <h4 class="display-1 font-weight-light mt-2">
                    {{ vehiclesWithNoActivity.data.series[0][vehiclesWithNoActivity.data.series[0].length - 1] }} Vehicles With No Activity
                  </h4>
                </base-material-chart-card>
              </router-link>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <base-material-card
          icon="mdi-currency-usd"
          :title="getSalesTitle()"
        >
          <v-row>
            <v-col>
              <v-data-table
                class="striped_table"
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="vendors"
                :items-per-page="50"
                :sort-by="['vendor']"
              >
                <template v-slot:item.call_percent_change="{ item }">
                  <template v-if="item.vendor != unattributedSaleName()">
                    <v-icon
                      slot="icon"
                      :color="getPercentChangeColor(item.call_percent_change)"
                    >
                      {{ getPercentChangeIcon(item.call_percent_change) }}
                    </v-icon>
                    {{ item.call_percent_change }}%
                  </template>
                </template>
                <template v-slot:item.lead_percent_change="{ item }">
                  <template v-if="item.vendor != unattributedSaleName()">
                    <v-icon
                      slot="icon"
                      :color="getPercentChangeColor(item.lead_percent_change)"
                    >
                      {{ getPercentChangeIcon(item.lead_percent_change) }}
                    </v-icon>
                    {{ item.lead_percent_change }}%
                  </template>
                </template>
                <template v-slot:item.sales_percent_change="{ item }">
                  <template v-if="item.vendor != unattributedSaleName()">
                    <v-icon
                      slot="icon"
                      :color="getPercentChangeColor(item.sales_percent_change)"
                    >
                      {{ getPercentChangeIcon(item.sales_percent_change) }}
                    </v-icon>
                    {{ item.sales_percent_change }}%
                  </template>
                </template>

                <template #item.budget="{value}">
                  <template v-if="(parseInt(value) > 0)">
                    {{ formatPrice(value) }}
                  </template>
                </template>
                <template v-slot:item.leads="{ item }">
                  <router-link
                    v-if="item.vendor != unattributedSaleName()"
                    :to="{ name: 'Leads', params: { vendor_id: item.id }, query: { start_date: startDate, end_date: endDate} }"
                  >
                    {{ formatNumber(item.leads) }}
                  </router-link>
                </template>
                <template v-slot:item.calls="{ item }">
                  <router-link
                    v-if="item.vendor != unattributedSaleName()"
                    :to="{ name: 'Calls', params: { vendor_id: item.id }, query: { start_date: startDate, end_date: endDate} }"
                  >
                    {{ formatNumber(item.calls) }}
                  </router-link>
                </template>
                <template v-slot:item.sales="{ item }">
                  <router-link
                    v-if="item.vendor != unattributedSaleName()"
                    :to="{ name: 'Vendor Deals', params: { vendor_id: item.id }, query: { start_date: startDate, end_date: endDate} }"
                  >
                    {{ item.sales }}
                  </router-link>
                  <template v-else>
                    <router-link
                      :to="{ name: 'Sold Vehicles', query: { start_date: startDate, end_date: endDate, unattributed: true} }"
                    >
                      {{ item.sales }}
                    </router-link>
                  </template>
                </template>
                <template v-slot:item.gross="{ item }">
                  <router-link
                    v-if="item.vendor != unattributedSaleName()"
                    :to="{ name: 'Vendor Deals', params: { vendor_id: item.id }, query: { start_date: startDate, end_date: endDate} }"
                  >
                    {{ formatPrice(item.gross) }}
                  </router-link>
                </template>
                <template v-slot:item.vendor="{ item }">
                  <template v-if="item.vendor === unattributedSaleName()">
                    <span class="font-weight-bold">
                      {{ item.vendor }}
                    </span>
                  </template>
                  <template v-else>
                    {{ item.vendor }}
                  </template>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col
        cols="6"
        sm="12"
        lg="6"
      >
        <base-material-card
          id="website_traffic"
          color="warning"
          icon="mdi-chart-timeline-variant"
          class="px-4 py-3"
          title="Website Traffic"
        >
          <template v-if="websiteTraffic.data.series_name.length > 0">
            <v-chip
              class="ma-2"
              color="green lighten-1"
              label
              text-color="white"
            >
              {{ websiteTraffic.data.series_name[0] }}
            </v-chip>
            <v-chip
              class="ma-2"
              color="primary"
              label
              text-color="white"
            >
              {{ websiteTraffic.data.series_name[1] }}
            </v-chip>
            <chartist
              :data="websiteTraffic.data"
              :options="websiteTraffic.options"
              type="Line"
              style="max-height: 150px;"
              class="mt-3"
            />
          </template>
        </base-material-card>
      </v-col>
      <v-col
        cols="6"
        sm="12"
        lg="6"
      >
        <base-material-card
          id="referral_traffic"
          color="warning"
          icon="mdi-chart-timeline-variant"
          class="px-4 py-3"
          title="Top VDP View Sources"
        >
          <template v-if="topVdpViews.data.series_name.length > 0">
            <v-chip
              class="ma-2"
              color="red"
              label
              text-color="white"
            >
              {{ topVdpViews.data.series_name[0] }}
            </v-chip>
          </template>
          <template v-if="topVdpViews.data.series_name.length > 1">
            <v-chip
              class="ma-2"
              color="primary"
              label
              text-color="white"
            >
              {{ topVdpViews.data.series_name[1] }}
            </v-chip>
          </template>
          <template v-if="topVdpViews.data.series_name.length > 2">
            <v-chip
              class="ma-2"
              color="orange"
              label
              text-color="white"
            >
              {{ topVdpViews.data.series_name[2] }}
            </v-chip>
          </template>
          <chartist
            :data="topVdpViews.data"
            :options="topVdpViews.options"
            type="Bar"
            style="max-height: 150px;"
            class="mt-3"
          />
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import DashboardService from '@/services/DashboardService.js'
  import { mapGetters } from 'vuex'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { countColorsMixin } from '@/mixins/countColorsMixin.js'

  export default {
    name: 'Home',
    components: {
    },
    mixins: [formatterMixin, countColorsMixin],
    data () {
      return {
        loading: false,
        error: '',
        headers: [
          { text: 'Vendor', value: 'vendor' },
          { text: 'Leads', value: 'leads' },
          { text: 'Lead % Change', value: 'lead_percent_change' },
          { text: 'Calls', value: 'calls' },
          { text: 'Call % Change', value: 'call_percent_change' },
          { text: 'Sales', value: 'sales' },
          { text: 'Sale % Change', value: 'sales_percent_change' },
          { text: 'Budget', value: 'budget' },
          { text: 'Gross', value: 'gross' },
        ],
        vendors: [],
        websiteTraffic: {
          data: {
            labels: [],
            series: [],
            series_name: [],
          },
          options: {
            low: 0,
            high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            showArea: false,
            showPoint: false,
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        topVdpViews: {
          data: {
            labels: [],
            series: [],
            series_name: [],
          },
          options: {
            low: 0,
            // high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            showArea: false,
            showPoint: false,
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        vehiclesNotOnWebsite: {
          data: {
            labels: [],
            series: [
              [],
            ],
          },
          options: {
            low: 0,
            high: 0,
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        vehiclesWithNoPrice: {
          data: {
            labels: [],
            series: [
              [],
            ],
          },
          options: {
            low: 0,
            high: 0,
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        vehiclesWithIncompletePhotos: {
          data: {
            labels: [],
            series: [
              [],
            ],
          },
          options: {
            low: 0,
            high: 0,
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        vehiclesWithNoActivity: {
          data: {
            labels: [],
            series: [
              [],
            ],
          },
          options: {
            low: 0,
            high: 0,
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        dashboardData: [],
        ageStats: [],
        inStock: 0,
        graphLabels: [],
        graphColors: [],
        graphColorEmpty: false,
        lastUpdatedAt: '',
        forceCacheUpdate: false,
        startDate: '',
        endDate: '',
        mtd_sales: 0,
        prior_mtd_sales: 0,
        mtd_sales_delta: 0,
      }
    },
    computed: {
      ...mapGetters(['accountId', 'accountName']),
    },
    watch: {
      accountId () {
        this.loadHomeDashboard()
      },
    },
    created () {
      this.loadHomeDashboard()
      // this.notifyMe()
    },
    methods: {
      loadHomeDashboard: function () {
        this.loading = true
        this.dashboardData = []
        var date = new Date()
        this.endDate = this.formatJSDate(date)
        date = new Date(date.getFullYear(), date.getMonth(), 1)
        this.startDate = this.formatJSDate(date)
        DashboardService.getHomeDashboard(this.forceCacheUpdate)
          .then(response => {
            this.dashboardData = response.data
            this.vendors = response.data.vendor_stats
            this.ageStats = response.data.age_stats
            this.inStock = response.data.in_stock
            this.vehiclesNotOnWebsite.data = response.data.not_on_website
            this.vehiclesNotOnWebsite.options.high = Math.max(...this.vehiclesNotOnWebsite.data.series[0])
            this.vehiclesWithNoPrice.data = response.data.needs_pricing
            this.vehiclesWithNoPrice.options.high = Math.max(...this.vehiclesWithNoPrice.data.series[0])
            this.vehiclesWithIncompletePhotos.data = response.data.incomplete_photos
            this.vehiclesWithIncompletePhotos.options.high = Math.max(...this.vehiclesWithIncompletePhotos.data.series[0])
            this.vehiclesWithNoActivity.data = response.data.no_activity
            this.vehiclesWithNoActivity.options.high = Math.max(...this.vehiclesWithNoActivity.data.series[0])
            this.websiteTraffic.data = response.data.website_traffic
            this.websiteTraffic.options.high = Math.max(...this.websiteTraffic.data.series[0])
            this.topVdpViews.data = response.data.top_vdp_views
            this.graphColors = response.data.graph_colors
            this.graphColorEmpty = response.data.graph_color_empty
            this.lastUpdatedAt = response.data.generated_at
            this.forceCacheUpdate = false
            this.mtd_sales = response.data.mtd_sales
            this.prior_mtd_sales = response.data.prior_mtd_sales
            this.mtd_sales_delta = response.data.mtd_sales_delta
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              if (errorMessage) {
                this.error = errorMessage
              } else {
                this.error = 'Uh oh! There was a problem loading the page. Our support department has been contacted.'
              }
            }
          })
      },
      // displayNotification () {
      //   if (Notification.permission === 'granted') {
      //     navigator.serviceWorker.getRegistration().then(function (reg) {
      //       console.log('reg =' + reg)
      //       var options = {
      //         body: 'Here is a notification body!',
      //         icon: 'images/example.png',
      //         vibrate: [100, 50, 100],
      //         data: {
      //           dateOfArrival: Date.now(),
      //           primaryKey: 1,
      //         },
      //       }
      //       reg.showNotification('Hello world!', options)
      //     })
      //   }
      // },
      notifyMe () {
        // Let's check if the browser supports notifications
        if (!('Notification' in window)) { alert('This browser does not support desktop notification') }

        // Let's check whether notification permissions have already been granted
        if (Notification.permission === 'granted') {
          // If it's okay let's create a notification
          // eslint-disable-next-line no-new
          new Notification('Welcome to Dealer Insights')
        }

        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them any more.
      },
      getSalesTitle () {
        return 'MTD Retail Sales - ' + this.mtd_sales + ' Vehicles ( ' + this.mtd_sales_delta + '% change from same time period last month)'
      },
      getAgeTitle () {
        if (this.inStock) {
          return 'Total Inventory - ' + this.inStock + ' Vehicles'
        }
      },
      getAgeBoxColor (index) {
        switch (index) {
          case 0:
            return 'green lighten-1'
          case 1:
            return 'yellow accent-4'
          case 2:
            return 'orange'
          case 3:
            return 'red darken-1'
        }
      },
      getGraphColor (color) {
        switch (color) {
          case 'green':
            return 'green lighten-1'
          case 'yellow':
            return 'orange'
          case 'red':
            return 'red darken-1'
        }
      },
      refreshCache () {
        this.forceCacheUpdate = true
        this.loadHomeDashboard()
      },
    },
  }
</script>
<style lang="sass">
  // .ct-series-a .ct-bar,
  .ct-series-a .ct-line,
  .ct-series-a .ct-point
    stroke: #50AE4A !important
  // .ct-series-b .ct-bar,
  .ct-series-b .ct-line,
  .ct-series-b .ct-point
    stroke: #387CFC !important
  // .ct-series-c .ct-bar,
  .ct-series-c .ct-line,
  .ct-series-c .ct-point
    stroke: orange !important
  #referral_traffic
    .ct-series-a .ct-bar,
    .ct-series-a .ct-line,
    .ct-series-a .ct-point
      stroke: red !important
    .ct-series-b .ct-bar,
    .ct-series-b .ct-line,
    .ct-series-b .ct-point
      stroke: #387CFC !important
    .ct-series-c .ct-bar,
    .ct-series-c .ct-line,
    .ct-series-c .ct-point
      stroke: orange !important
  .age_boxes
    text-decoration: none
</style>
